import { LANGUAGES_TOP_PAGE } from '../common/relative-links';

const juniorPositions = { title: "Востребованность Junior специалистов", link: "/positions/juniors" };
const seniorPositions = { title: "Востребованность Senior специалистов", link: "/positions/seniors" };
const topLanguages = { title: "Топ языков программирования в РБ", link: LANGUAGES_TOP_PAGE };
const topCompanies = { title: "Топ IT компаний РБ по кол-ву сотрудников", link: "/biggest-it-companies-by-employees-in-belarus" };
const topDevelopersByLanguages = { title: "Популярность разработчиков по языкам", link: "/demand-in-it/various-technologies" };
const frontend = { title: "Востребованность frontend разработчиков", link: "/path/frontend" };
const backend = { title: "Востребованность backend разработчиков", link: "/path/backend" };
const mobile = { title: "Востребованность мобильных разработчиков", link: "/path/mobile" };
const devops = { title: "Востребованность devops специалистов", link: "/path/devops" };
const manualTesting = { title: "Востребованность QA специалистов", link: "/path/testing/qa-engineer" };
const automatedTesting = { title: "Востребованность QA Automation специалистов", link: "/path/testing/test-automation-engineer" };
const resumes = { title: "Сколько людей ищет работу", link: "/resumes" };

export const topCompaniesRelatedList = [
  juniorPositions,
  seniorPositions,
  topLanguages,
  topDevelopersByLanguages
];

export const topLanguagesRelatedList = [
  juniorPositions,
  seniorPositions,
  topCompanies,
  topDevelopersByLanguages
]

export const topsRelatedList = [
  juniorPositions,
  topDevelopersByLanguages,
  mobile,
  manualTesting
]

export const developersBackendRelatedList = [
  mobile,
  frontend,
  automatedTesting,
  devops
]

export const developersFrontendRelatedList = [
  mobile,
  backend,
  automatedTesting,
  devops
]

export const developersMobileRelatedList = [
  frontend,
  backend,
  automatedTesting
]

export const manualTestingRelatedList = [
  automatedTesting,
  frontend,
  backend,
  topLanguages,
  devops
]

export const automataedTestingRelatedList = [
  manualTesting,
  frontend,
  backend,
  topDevelopersByLanguages,
  devops
]

export const devopsRelatedList = [
  topCompanies,
  topLanguages,
  backend
]

export const courseRelatedPagesList = [
  juniorPositions,
  seniorPositions,
  topLanguages,
  topCompanies,
  topDevelopersByLanguages,
  manualTesting,
  mobile,
  frontend,
  automatedTesting,
  backend,
  resumes,
  devops
]

export const devstartxyzPagesList = [
  { title: "Айти стажировки и курсы в Сербии", link: "https://devstart.xyz/ru/serbia/vse-ayti-kursy-i-stajirovki" },
  { title: "Айти стажировки и курсы в Грузии", link: "https://devstart.xyz/ru/georgia/vse-ayti-kursy-i-stajirovki" },
  { title: "Айти стажировки и курсы в Узбекистане", link: "https://devstart.xyz/ru/uzbekistan/vse-ayti-kursy-i-stajirovki" }
]